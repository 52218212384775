import { memo } from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { IFetchClubSignupData } from '../../../api';

interface Props {
  pageData: IFetchClubSignupData;
}

function ClubOverview({ pageData }: Props): JSX.Element {
  const { brewery, club } = pageData;
  const { cost, membershipDurationMonths, name } = club;
  const formattedDuration = formatDuration(membershipDurationMonths);

  return (
    <Box
      py={6}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Heading as="h1" fontSize="xl" fontWeight="bold">
          {brewery.name}
        </Heading>

        <Heading as="h2" fontSize="md" color="gray.500" fontWeight="normal">
          {name}
        </Heading>
      </Box>

      {cost && (
        <Box
          alignItems="baseline"
          color="white"
          bg="green.300"
          borderRadius={4}
          display="flex"
          gap={1}
          px={2}
        >
          <Text fontSize="xl" fontWeight="semibold">
            ${cost}
          </Text>
          {formattedDuration && <Text fontSize="sm">{formattedDuration}</Text>}
        </Box>
      )}
    </Box>
  );
}

function formatDuration(
  membershipDurationMonths?: number | null,
): string | undefined {
  if (!membershipDurationMonths) {
    return;
  }

  if (membershipDurationMonths === 1) {
    return '/ monthly';
  }

  return `/ ${membershipDurationMonths} months`;
}

export default memo(ClubOverview);
