import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Heading, Link } from '@chakra-ui/react';
import { useRouteAuthenticatedUser } from '../../hooks';

const LandingPage = () => {
  useRouteAuthenticatedUser();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      h="calc(var(--vh, 1vh) * 100)"
    >
      <div>
        <Heading as="h1">🍺 Mug Club</Heading>

        <Box mt={4} display="flex" justifyContent="space-around">
          <Link to="/sign-up" as={RouterLink}>
            <Button>Sign Up</Button>
          </Link>
          <Link to="/log-in" as={RouterLink}>
            <Button>Log In</Button>
          </Link>
        </Box>
      </div>
    </Box>
  );
};

export default LandingPage;
