import { BreweryConfigOptions } from '../../types';

export enum MessageGroup {
  BIRTHDAYS = 'birthdays',
  CLUB_SIGN_UP = 'clubSignUp',
  MEMBERSHIP_RENEWAL = 'membershipRenewal',
  REWARDS = 'rewards',
}

export const groupContentByMessageGroup: Record<
  MessageGroup,
  { title: string; description: string }
> = {
  [MessageGroup.BIRTHDAYS]: {
    title: 'Birthdays',
    description: 'Messages sent to members during their birthday month.',
  },
  [MessageGroup.CLUB_SIGN_UP]: {
    title: 'Club Sign Up',
    description:
      'Messages shown to members as they move through the signup process for your club.',
  },
  [MessageGroup.MEMBERSHIP_RENEWAL]: {
    title: 'Membership Renewal Reminders',
    description:
      'Messages sent to members when their membership is ready for renewal.',
  },
  [MessageGroup.REWARDS]: {
    title: 'Rewards',
    description:
      'Messages shown to members as they earn rewards for their loyalty.',
  },
};

export interface MessageContent {
  configOption: BreweryConfigOptions;
  label: string;
  description: string;
  messageType: 'web' | 'email';
}

export const messageContentByConfigOption: Record<
  BreweryConfigOptions,
  MessageContent
> = {
  [BreweryConfigOptions.clubSignUpInstructions]: {
    configOption: BreweryConfigOptions.clubSignUpInstructions,
    label: 'Sign Up Instructions',
    description:
      'Displayed at the top of the club sign up form. Use this message to provide any instructions that visitors should know before signing up for your club.',
    messageType: 'web',
  },
  [BreweryConfigOptions.clubSignUpConfirmation]: {
    configOption: BreweryConfigOptions.clubSignUpConfirmation,
    label: 'Sign Up Confirmation',
    description:
      'Displayed after a new member signs up for your club. Use this message to provide next steps or additional information you would like new members to know.',
    messageType: 'web',
  },
  [BreweryConfigOptions.patronSignedUpForClubEmailEnabled]: {
    configOption: BreweryConfigOptions.patronSignedUpForClubEmailEnabled,
    label: 'Sign Up Email',
    description: 'Email sent to members after they sign up for a club.',
    messageType: 'email',
  },
  [BreweryConfigOptions.patronOneMonthRenewalReminderEmail]: {
    configOption: BreweryConfigOptions.patronOneMonthRenewalReminderEmail,
    label: 'One-Month Renewal Reminder',
    description:
      'Email sent to members one-month before their membership expires.',
    messageType: 'email',
  },
  [BreweryConfigOptions.patronOneWeekRenewalReminderEmail]: {
    configOption: BreweryConfigOptions.patronOneWeekRenewalReminderEmail,
    label: 'One-Week Renewal Reminder',
    description:
      'Email sent to members one-week before their membership expires.',
    messageType: 'email',
  },
  [BreweryConfigOptions.birthdayMessageEnabled]: {
    configOption: BreweryConfigOptions.birthdayMessageEnabled,
    label: 'Birthday Month Message',
    description:
      'Email sent to active members on the first day of their birthday month.',
    messageType: 'email',
  },
  [BreweryConfigOptions.patronEarnedRewardEmailEnabled]: {
    configOption: BreweryConfigOptions.patronEarnedRewardEmailEnabled,
    label: 'Earned Reward',
    description: 'Email sent to patrons when they earn a new reward.',
    messageType: 'email',
  },
};

export const messagesByMessageGroup: Record<MessageGroup, MessageContent[]> = {
  [MessageGroup.CLUB_SIGN_UP]: [
    messageContentByConfigOption[BreweryConfigOptions.clubSignUpInstructions],
    messageContentByConfigOption[BreweryConfigOptions.clubSignUpConfirmation],
    messageContentByConfigOption[
      BreweryConfigOptions.patronSignedUpForClubEmailEnabled
    ],
  ],
  [MessageGroup.MEMBERSHIP_RENEWAL]: [
    messageContentByConfigOption[
      BreweryConfigOptions.patronOneMonthRenewalReminderEmail
    ],
    messageContentByConfigOption[
      BreweryConfigOptions.patronOneWeekRenewalReminderEmail
    ],
  ],
  [MessageGroup.BIRTHDAYS]: [
    messageContentByConfigOption[BreweryConfigOptions.birthdayMessageEnabled],
  ],
  [MessageGroup.REWARDS]: [
    messageContentByConfigOption[
      BreweryConfigOptions.patronEarnedRewardEmailEnabled
    ],
  ],
};
