import { Badge, Box, Text } from '@chakra-ui/react';
import { IBrewery, ProductPlan } from '../../../types';
import { memo } from 'react';

const priceByPlan: Record<ProductPlan, number> = {
  [ProductPlan.FREE]: 0,
  [ProductPlan.STARTER]: 49,
  [ProductPlan.CORE]: 99,
  [ProductPlan.COMPLETE]: 149,
};

interface Props {
  brewery: IBrewery;
  isTrialingPaidPlan: boolean;
}

function SubscriptionDetails({
  brewery,
  isTrialingPaidPlan,
}: Props): JSX.Element {
  const { plan } = brewery;
  const price = `$${priceByPlan[plan]}`;

  return (
    <Box display="grid" gap={2}>
      <Text fontWeight="medium">Subscription Details</Text>

      {isTrialingPaidPlan && (
        <Text fontSize="sm">
          <b>Note:</b> You are currently on a free trial of the {plan} plan.
        </Text>
      )}

      <Box>
        <Text fontWeight="medium" fontSize="sm" mb={1}>
          Plan
        </Text>
        <Badge fontSize="sm" textTransform="capitalize" rounded="md">
          {plan}
        </Badge>
      </Box>

      <Box>
        <Text fontWeight="medium" fontSize="sm" mb={1}>
          Monthly Price
        </Text>
        <Badge fontSize="sm" textTransform="capitalize" rounded="md">
          {price}
        </Badge>
      </Box>
    </Box>
  );
}

export default memo(SubscriptionDetails);
