import { memo } from 'react';
import { Box } from '@chakra-ui/react';
import MessageGroupHeader from './MessageGroupHeader';
import ListItem from './ListItem';
import { messagesByMessageGroup, MessageGroup } from '../../constants';

function MessagesList(): JSX.Element {
  return (
    <Box overflowY="auto" h="calc(100vh - 106px)">
      <MessageGroupHeader messageGroup={MessageGroup.CLUB_SIGN_UP} />

      {messagesByMessageGroup[MessageGroup.CLUB_SIGN_UP].map((message) => (
        <ListItem key={message.configOption} messageContent={message} />
      ))}

      <MessageGroupHeader messageGroup={MessageGroup.MEMBERSHIP_RENEWAL} />

      {messagesByMessageGroup[MessageGroup.MEMBERSHIP_RENEWAL].map(
        (message) => (
          <ListItem key={message.configOption} messageContent={message} />
        ),
      )}

      <MessageGroupHeader messageGroup={MessageGroup.REWARDS} />

      {messagesByMessageGroup[MessageGroup.REWARDS].map((message) => (
        <ListItem key={message.configOption} messageContent={message} />
      ))}

      <MessageGroupHeader messageGroup={MessageGroup.BIRTHDAYS} />

      {messagesByMessageGroup[MessageGroup.BIRTHDAYS].map((message) => (
        <ListItem key={message.configOption} messageContent={message} />
      ))}
    </Box>
  );
}

export default memo(MessagesList);
