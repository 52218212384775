import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
export * from './firestore';
export {
  fetchClubSignupPageData,
  type IReturnData as IFetchClubSignupData,
} from './fetchClubSignupPageData';
export { processPatronSignup } from './processPatronSignup';

export const validateAdminUser = httpsCallable<
  { email: string },
  { isValid: boolean }
>(functions, 'validateAdminUser');

export const createStripeBillingPortalSession = httpsCallable<
  undefined,
  { sessionUrl: string }
>(functions, 'createStripeBillingPortalSession');
