import { memo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { MessageGroup, groupContentByMessageGroup } from '../../constants';

type Props = {
  messageGroup: MessageGroup;
};

function MessageGroupHeader({ messageGroup }: Props): JSX.Element {
  const { title, description } = groupContentByMessageGroup[messageGroup];

  return (
    <Box
      borderBottom="1px solid"
      borderColor="gray.200"
      p={4}
      borderLeftColor="black"
    >
      <Text fontWeight="medium">{title}</Text>
      <Text color="gray.500" fontSize="sm">
        {description}
      </Text>
    </Box>
  );
}

export default memo(MessageGroupHeader);
