import { memo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import MessageIcon from '../MessageIcon';
import { useMessagesPageContext } from '../../context';
import { MessageContent } from '../../constants';
import { ChevronRight } from '../../../../assets';

type Props = {
  messageContent: MessageContent;
};

function ListItem({ messageContent }: Props): JSX.Element {
  const { selectedConfig, setSelectedConfig } = useMessagesPageContext();
  const { configOption, label, messageType } = messageContent;
  const isSelected = configOption === selectedConfig;

  return (
    <Box
      alignItems="center"
      borderBottom="1px solid"
      borderColor="gray.200"
      bg={isSelected ? 'gray.100' : 'white'}
      cursor="pointer"
      display="grid"
      gap={1}
      gridTemplateColumns="auto 20px"
      onClick={() => setSelectedConfig(configOption)}
      p={4}
      _hover={{ bg: 'gray.100' }}
    >
      <Box>
        <Text
          alignItems="center"
          display="flex"
          gap={1.5}
          fontSize="sm"
          fontWeight="medium"
        >
          <MessageIcon messageType={messageType} />
          {label}
        </Text>
      </Box>

      <ChevronRight fontSize="xl" />
    </Box>
  );
}

export default memo(ListItem);
