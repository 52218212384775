import { memo } from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import CustomerPortalButton from './CustomerPortalButton';
import UpgradePlanButton from './UpgradePlanButton';
import SubscriptionDetails from './SubscriptionDetails';
import { IBrewery, ProductPlan } from '../../../types';

interface Props {
  brewery: IBrewery;
}

function SubscriptionTab({ brewery }: Props): JSX.Element {
  const { plan, stripeCustomerId } = brewery;
  const isStripeCustomer = Boolean(stripeCustomerId);
  const isTrialingPaidPlan = !isStripeCustomer && plan !== ProductPlan.FREE;

  return (
    <Box maxWidth={580}>
      <Heading as="h2" fontSize="lg" mb={4}>
        Manage Subscription
      </Heading>

      <Box mb={4}>
        <SubscriptionDetails
          brewery={brewery}
          isTrialingPaidPlan={isTrialingPaidPlan}
        />
      </Box>

      <Box mb={4}>
        {isStripeCustomer ? <CustomerPortalButton /> : <UpgradePlanButton />}
      </Box>

      <Box>
        <Text fontWeight="medium" mb={2}>
          Need Help?
        </Text>

        <Text fontSize="sm">
          If you have billing questions, please reach out to:{' '}
          <b>help@mugclub.beer</b>
        </Text>
      </Box>
    </Box>
  );
}

export default memo(SubscriptionTab);
