import { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  VStack,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  Textarea,
} from '@chakra-ui/react';
import SectionHeading from '../SectionHeading';
import SectionNavigation from '../SectionNavigation';
import { useClubCreatorContext } from '../Context';
import { DollarSign, Users } from '../../../assets';

export default function ClubDetails(): JSX.Element {
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasCostError, setHasCostError] = useState<boolean>(false);
  const { formValues, updateFormValues, stepForward } = useClubCreatorContext();
  const { cost, name, renewalCost, capacity } = formValues;

  function handleOnSubmit() {
    setHasError(false);
    setHasCostError(false);

    if (!name) {
      setHasError(true);
    }

    if (!cost) {
      setHasCostError(true);
    }

    if (!name || !cost) {
      return;
    }

    stepForward();
  }

  function handleOnChange({
    currentTarget,
  }: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { id, value } = currentTarget;

    updateFormValues({ [id]: value });
  }

  return (
    <Box>
      <SectionHeading
        title="Club Basics"
        description="Let's start with the basics of your next mug club."
      />
      <VStack spacing={4} mb={6} alignContent="flex-start">
        <FormControl isRequired isInvalid={hasError}>
          <FormLabel htmlFor="name" fontSize="sm">
            Club Name
          </FormLabel>
          <Input
            id="name"
            type="name"
            placeholder="Club Name"
            value={name}
            onChange={handleOnChange}
            onBlur={() => setHasError(!name)}
          />
          <FormErrorMessage>This field is required.</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="description" fontSize="sm">
            Club Description
          </FormLabel>

          <Textarea
            id="description"
            placeholder="Club Description"
            value={capacity}
            onChange={handleOnChange}
          />
        </FormControl>

        <FormControl isRequired isInvalid={hasCostError}>
          <FormLabel htmlFor="cost" fontSize="sm">
            Membership Cost
          </FormLabel>

          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <DollarSign color="gray.300" />
            </InputLeftElement>

            <Input
              id="cost"
              type="number"
              placeholder="Cost"
              value={cost}
              onChange={handleOnChange}
            />
          </InputGroup>

          <FormHelperText>
            What will members pay to join this club?
          </FormHelperText>
          <FormErrorMessage>This field is required.</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="renewalCost" fontSize="sm">
            Renewal Cost
          </FormLabel>

          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <DollarSign color="gray.300" />
            </InputLeftElement>

            <Input
              id="renewalCost"
              type="number"
              placeholder="Renewal Cost"
              value={renewalCost}
              onChange={handleOnChange}
            />
          </InputGroup>

          <FormHelperText>
            What do existing members pay to renew?
          </FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="capacity" fontSize="sm">
            Capacity
          </FormLabel>

          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Users color="gray.300" />
            </InputLeftElement>

            <Input
              id="capacity"
              type="number"
              placeholder="Capacity"
              value={capacity}
              onChange={handleOnChange}
            />
          </InputGroup>

          <FormHelperText>
            Is there a limit to how many members can join?
          </FormHelperText>
        </FormControl>
      </VStack>

      <SectionNavigation onNext={handleOnSubmit} />
    </Box>
  );
}
