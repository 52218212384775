import {
  BreweryConfigOptions,
  IBrewery,
  IBreweryConfig,
  ProductPlan,
} from '../../../../types';
import MessageTextArea from '../MessageTextArea';
import ReadOnlyMessageTextArea from '../ReadOnlyMessageTextArea';

type TextAreaProps = {
  brewery: IBrewery;
  breweryConfig: IBreweryConfig;
};

function formatRenewalReminderCopy(brewery: IBrewery, window: string): string {
  const { name, website } = brewery;

  return `Hello {{PATRON_FIRST_NAME}},\n
This is a friendly reminder that your mug club membership at ${name} expires in one ${window}.\n
Please visit ${website} for instructions on how to renew your membership.\n
Thank you,\n
The team at ${name}
  `;
}

export function OneMonthMembershipRenewal({
  brewery,
}: TextAreaProps): JSX.Element {
  return (
    <ReadOnlyMessageTextArea
      defaultValue={formatRenewalReminderCopy(brewery, 'month')}
      description="Email sent to patrons one-month before their membership expires."
      label="One-Month Renewal Reminder"
      messageType="email"
      rows={12}
    />
  );
}

export function OneWeekMembershipRenewal({
  brewery,
}: TextAreaProps): JSX.Element {
  return (
    <ReadOnlyMessageTextArea
      defaultValue={formatRenewalReminderCopy(brewery, 'week')}
      description="Email sent to patrons one-week before their membership expires."
      label="One-Week Renewal Reminder"
      messageType="email"
      rows={12}
    />
  );
}

export function ClubSignUpInstructions({
  brewery,
  breweryConfig,
}: TextAreaProps): JSX.Element {
  const { plan } = brewery;
  const hasFreePlan = plan === ProductPlan.FREE;

  return (
    <MessageTextArea
      breweryId={brewery.id}
      configOption={BreweryConfigOptions.clubSignUpInstructions}
      defaultValue={breweryConfig.clubSignUpInstructions}
      description="Displayed at the top of the club sign up form. Use this message to provide any instructions that patrons should know before signing up for your club."
      isFeatureGated={hasFreePlan}
      label="Sign Up Instructions"
      messageType="web"
    />
  );
}

export function ClubSignUpConfirmation({
  brewery,
  breweryConfig,
}: TextAreaProps): JSX.Element {
  const { plan } = brewery;
  const hasFreePlan = plan === ProductPlan.FREE;

  return (
    <MessageTextArea
      breweryId={brewery.id}
      configOption={BreweryConfigOptions.clubSignUpConfirmation}
      defaultValue={breweryConfig.clubSignUpConfirmation}
      description="Displayed after a patron signs up for a club. Use this message to provide next steps or additional information you would like new members to know."
      isFeatureGated={hasFreePlan}
      label="Sign Up Confirmation"
      messageType="web"
    />
  );
}

function formatPatronSignUpEmail(brewery: IBrewery): string {
  const { name } = brewery;

  return `👋 Hello {{PATRON_FIRST_NAME}},\n
Welcome! This email confirms your registration for ${name}'s mug club. If you haven't already, please see a member of brewery staff to activate your membership.\n
If you have any questions, please ask a member of brewery staff for assistance.\n
Cheers,\n
The team at ${name}
  `;
}

export function PatronSignedUpForClubEmail({
  brewery,
  breweryConfig,
}: TextAreaProps): JSX.Element {
  const { patronSignedUpForClubEmailEnabled } = breweryConfig;

  return (
    <ReadOnlyMessageTextArea
      breweryId={brewery.id}
      configOption={BreweryConfigOptions.patronSignedUpForClubEmailEnabled}
      defaultValue={formatPatronSignUpEmail(brewery)}
      isDefaultEnabled={patronSignedUpForClubEmailEnabled}
      description="Email sent to patrons after they sign up for a club."
      isToggleable={true}
      label="Sign Up Email"
      messageType="email"
      rows={12}
    />
  );
}

function formatRenewalReminder(brewery: IBrewery): string {
  const { name } = brewery;

  return `👋 Hello {{PATRON_FIRST_NAME}},\n
Congratulations! You earned a reward at ${name}:\n
{{REWARD_DESCRIPTION}}\n
Please stop by to redeem it.\n
Cheers,\n
The team at ${name}`;
}

export function PatronEarnedReward({
  brewery,
  breweryConfig,
}: TextAreaProps): JSX.Element {
  const { patronEarnedRewardEmailEnabled } = breweryConfig;
  const { plan } = brewery;
  const hasFreePlan = plan === ProductPlan.FREE;

  return (
    <ReadOnlyMessageTextArea
      breweryId={brewery.id}
      configOption={BreweryConfigOptions.patronEarnedRewardEmailEnabled}
      defaultValue={formatRenewalReminder(brewery)}
      description="Email sent to patrons when they earn a new reward."
      isDefaultEnabled={patronEarnedRewardEmailEnabled}
      isFeatureGated={hasFreePlan}
      isToggleable
      label="Earned Reward"
      messageType="email"
      rows={12}
    />
  );
}

function formatBirthdayMessage(brewery: IBrewery): string {
  const { name } = brewery;

  return `Hello {{PATRON_FIRST_NAME}},\n
Wishing you a happy birthday month from all of us at ${name}!\n
Stop by the taproom any time over the next month to enjoy a birthday pour on us.\n
Looking forward to celebrating with you soon.\n
Cheers,\n
The team at ${name}`;
}

export function BirthdayMessage({
  brewery,
  breweryConfig,
}: TextAreaProps): JSX.Element {
  const { birthdayMessageEnabled } = breweryConfig;

  return (
    <ReadOnlyMessageTextArea
      breweryId={brewery.id}
      configOption={BreweryConfigOptions.birthdayMessageEnabled}
      defaultValue={formatBirthdayMessage(brewery)}
      description="Email sent to active patrons on the first day of their birthday month."
      isDefaultEnabled={birthdayMessageEnabled}
      isToggleable
      label="Birthday Month message"
      messageType="email"
      rows={12}
    />
  );
}
