import { Box } from '@chakra-ui/react';
import { MessageEditor, MessagesList } from './components';
import { MessagesPageProvider } from './context';
import { Layout } from '../../components';

function MessagesPage(): JSX.Element {
  return (
    <Layout
      pageDescription="Customize the messages your members see and receive."
      pageHeading="Messages"
    >
      <Box
        borderTop="1px solid"
        borderColor="gray.200"
        display="grid"
        gridTemplateColumns="400px auto"
        h="calc(100vh - 106px)"
        mx={-4}
      >
        <Box borderRight="1px solid" borderColor="gray.200">
          <MessagesList />
        </Box>

        <MessageEditor />
      </Box>
    </Layout>
  );
}

export default function MessagesPageWithProvider(): JSX.Element {
  return (
    <MessagesPageProvider>
      <MessagesPage />
    </MessagesPageProvider>
  );
}
