import { memo, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useGetBrewery } from '../../../contexts';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

function UpgradePlanButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [brewery] = useGetBrewery();

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upgrade Plan</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <stripe-pricing-table
              client-reference-id={brewery?.id}
              pricing-table-id={
                process.env.REACT_APP_PUBLIC_STRIPE_PRICING_TABLE_ID
              }
              publishable-key={
                process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY
              }
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box>
        <Text fontWeight="medium" mb={2}>
          Upgrade Your Plan
        </Text>

        <Button onClick={onOpen} size="sm">
          Upgrade
        </Button>
      </Box>
    </>
  );
}

export default memo(UpgradePlanButton);
