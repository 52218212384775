import { memo } from 'react';
import { Globe, Mail, Phone } from '../../../../assets';

const iconByMessageType: Record<MessageType, JSX.Element> = {
  email: <Mail />,
  sms: <Phone />,
  web: <Globe />,
};

type Props = {
  messageType: MessageType;
};

type MessageType = 'email' | 'sms' | 'web';

function MessageIcon({ messageType }: Props): JSX.Element {
  return iconByMessageType[messageType];
}

export default memo(MessageIcon);
