import { useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Link,
  Input,
  Text,
} from '@chakra-ui/react';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { captureException } from '@sentry/react';
import InvalidCodeError from './InvalidCodeError';
import Success from './Success';
import { PasswordInput } from '../../components';
import { LogoPatch } from '../../assets';
import { auth } from '../../firebase';
import { useQueryParams } from '../../hooks';

export default function ResetPassword(): JSX.Element {
  const query = useQueryParams();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [hasCodeError, setHasCodeError] = useState<boolean>(false);
  const [hasPasswordError, setHasPasswordError] = useState<boolean>(false);
  const [hasPasswordConfirmationError, setHasPasswordConfirmationError] =
    useState<boolean>(false);
  const oobCode = query.get('oobCode');

  useEffect(() => {
    async function verifyCode() {
      if (!oobCode) {
        return;
      }

      try {
        const email = await verifyPasswordResetCode(auth, oobCode);
        setEmail(email);
      } catch (error) {
        setHasCodeError(true);
      }
    }

    verifyCode();
  }, [oobCode]);

  if (showSuccess) {
    return <Success />;
  }

  if (hasCodeError) {
    return <InvalidCodeError />;
  }

  async function handleOnSubmit(event: React.SyntheticEvent) {
    event.preventDefault();

    setHasPasswordConfirmationError(false);
    setHasPasswordError(false);

    if (password.trim() !== confirmPassword.trim()) {
      setHasPasswordConfirmationError(true);
      return;
    }

    setIsLoading(true);

    try {
      await confirmPasswordReset(auth, oobCode!, password.trim());
      setShowSuccess(true);
    } catch (error) {
      captureException(error);
      setHasPasswordError(true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box
      bg="gray.100"
      h="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box bg="white" rounded="lg" shadow="md" p={6} w={384}>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          flexDir="column"
          gap={2}
          mb={6}
        >
          <LogoPatch height="48" width="48" />
          <Text fontSize="xl" fontWeight="semibold">
            Reset Password
          </Text>
        </Box>

        <Box textAlign="center" mb={4}>
          <Text color="gray.500" fontSize="sm">
            Enter a new password to complete the password reset process.
          </Text>
        </Box>

        <form onSubmit={handleOnSubmit}>
          <FormControl mb={4}>
            <FormLabel htmlFor="email" fontSize="sm">
              Email
            </FormLabel>
            <Input
              id="email"
              type="email"
              placeholder="Email"
              defaultValue={email}
              readOnly
            />
          </FormControl>

          <FormControl isRequired isInvalid={hasPasswordError} mb={4}>
            <FormLabel htmlFor="newPassword" fontSize="sm">
              New Password
            </FormLabel>

            <PasswordInput id="newPassword" onChange={setPassword} />

            <FormErrorMessage>
              Passwords must be at least six characters in length.
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            isInvalid={hasPasswordConfirmationError}
            mb={6}
          >
            <FormLabel htmlFor="confirmPassword" fontSize="sm">
              Confirm Password
            </FormLabel>

            <PasswordInput id="confirmPassword" onChange={setConfirmPassword} />

            <FormErrorMessage>Passwords must match.</FormErrorMessage>
          </FormControl>

          <Button
            w="100%"
            type="submit"
            bg="green.500"
            color="white"
            isLoading={isLoading}
            size="sm"
          >
            Reset Password
          </Button>
        </form>

        <Text mt={6} textAlign="center" color="gray.500" fontSize="sm">
          <Link as={ReactRouterLink} to="/">
            Click here
          </Link>{' '}
          to return to the Login page.
        </Text>
      </Box>
    </Box>
  );
}
