import { createContext, useContext, useEffect, useState } from 'react';
import { useGetBrewery } from '../../contexts';
import { getBreweryConfig } from '../../utils';
import { messagesByMessageGroup, MessageGroup } from './constants';
import { BreweryConfigOptions, IBreweryConfig } from '../../types';

const MessagesPageContext = createContext<Context | undefined>(undefined);

type Context = {
  breweryConfig: IBreweryConfig;
  messageGroup: MessageGroup;
  setMessageGroup: (messageGroup: MessageGroup) => void;
  selectedConfig: BreweryConfigOptions;
  setSelectedConfig: (selectedConfig: BreweryConfigOptions) => void;
};

type Props = {
  children: React.ReactNode;
};

function MessagesPageProvider({ children }: Props): JSX.Element {
  const [brewery] = useGetBrewery();
  const breweryConfig = getBreweryConfig(brewery);
  const [messageGroup, setMessageGroup] = useState<MessageGroup>(
    MessageGroup.CLUB_SIGN_UP,
  );
  const [selectedConfig, setSelectedConfig] = useState<BreweryConfigOptions>(
    BreweryConfigOptions.clubSignUpInstructions,
  );

  useEffect(() => {
    const messages = messagesByMessageGroup[messageGroup];
    setSelectedConfig(messages[0].configOption);
  }, [messageGroup]);

  const value = {
    breweryConfig,
    messageGroup,
    setMessageGroup,
    selectedConfig,
    setSelectedConfig,
  };

  return (
    <MessagesPageContext.Provider value={value}>
      {children}
    </MessagesPageContext.Provider>
  );
}

function useMessagesPageContext() {
  const context = useContext(MessagesPageContext);

  if (context === undefined) {
    throw new Error(
      'useMessagesPageContext must be used within MessagesPageProvider',
    );
  }

  return context;
}

export { MessagesPageContext, MessagesPageProvider, useMessagesPageContext };
