import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { updateBreweryConfig } from '../../utils';
import { FeatureGateText, MessageIcon } from '../';
import { getDefaultConfigValue } from '../../../../utils';
import { BreweryConfigOptions } from '../../../../types';

type Props = {
  breweryId: string;
  configOption: BreweryConfigOptions;
  defaultValue: string;
  description: string;
  isFeatureGated?: boolean;
  label: string;
  messageType: 'email' | 'sms' | 'web';
};

export default function MessageTextArea({
  breweryId,
  configOption,
  defaultValue,
  description,
  isFeatureGated = false,
  label,
  messageType,
}: Props): JSX.Element {
  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  async function handleSave() {
    setIsLoading(true);

    try {
      await updateBreweryConfig(breweryId, configOption, value);

      toast({
        description: 'Message template updated.',
        status: 'success',
        title: 'Success!',
      });
    } catch (error) {
      toast({
        description:
          'An error occurred updating this message template. Please try again.',
        status: 'error',
        title: 'Uh Oh.',
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleUseDefault() {
    const defaultConfigValue = getDefaultConfigValue(configOption);

    if (typeof defaultConfigValue === 'string') {
      setValue(defaultConfigValue);
    }
  }

  return (
    <Box maxW={640}>
      <FormControl mb={3}>
        <FormLabel alignItems="center" display="flex" gap={1} mb={1}>
          <MessageIcon messageType={messageType} />
          {label}
        </FormLabel>
        <Text color="gray.500" fontSize="sm" mb={3}>
          {description}
        </Text>
        <Textarea
          isDisabled={isFeatureGated}
          value={value}
          onChange={({ currentTarget }) => setValue(currentTarget.value)}
          rows={5}
        />
      </FormControl>

      {isFeatureGated && <FeatureGateText />}

      {!isFeatureGated && (
        <Box
          alignItems="center"
          display="flex"
          gap={2}
          justifyContent="flex-end"
        >
          <Button
            size="sm"
            variant="ghost"
            isDisabled={isLoading}
            onClick={handleUseDefault}
          >
            Use Default
          </Button>

          <Button
            size="sm"
            isDisabled={value === defaultValue || isLoading}
            isLoading={isLoading}
            onClick={handleSave}
            variant="outline"
          >
            Update
          </Button>
        </Box>
      )}
    </Box>
  );
}
