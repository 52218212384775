import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Switch,
  Text,
} from '@chakra-ui/react';
import { useClubCreatorContext } from '../Context';

export default function ClubDetailsList(): JSX.Element {
  const { formValues } = useClubCreatorContext();
  const { hasMugNumbers, hasPatronMugNumberSelection } = formValues;

  return (
    <Box>
      <Text fontSize="lg" fontWeight="semibold" mb={2}>
        Mug Numbers
      </Text>

      <FormControl mb={3}>
        <FormLabel mb={1} htmlFor="name" fontSize="sm">
          Mug Numbers
        </FormLabel>
        <FormHelperText mb={2} mt={0}>
          Assign patrons unique mug or membership numbers.
        </FormHelperText>

        <Switch isChecked={hasMugNumbers} isReadOnly />
      </FormControl>

      <FormControl>
        <FormLabel mb={1} fontSize="sm">
          Enable Patron Mug Number Selection
        </FormLabel>
        <FormHelperText mb={2} mt={0}>
          Allow patrons to pick their preferred mug number during registration.
        </FormHelperText>

        <Switch
          id="hasPatronMugNumberSelection"
          isChecked={hasPatronMugNumberSelection}
          readOnly
        />
      </FormControl>
    </Box>
  );
}
