import { memo, useEffect, useState } from 'react';
import { Button, Link, Text, useToast } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { createStripeBillingPortalSession } from '../../../api';
import { ExternalLinkIcon } from '../../../assets';

function CustomerPortalButton(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sessionUrl, setSessionUrl] = useState<string>('');
  const toast = useToast();

  useEffect(() => {
    async function createSession() {
      try {
        const { data } = await createStripeBillingPortalSession();
        setSessionUrl(data.sessionUrl);
        setIsLoading(false);
      } catch (error) {
        captureException(error);
        toast({
          title: 'Uh Oh',
          description:
            'An error occurred getting your billing information. Please try again.',
          status: 'error',
        });
      }
    }

    createSession();
  }, [toast]);

  return (
    <>
      <Text fontWeight="medium" mb={2}>
        Customer Portal
      </Text>

      <Text mb={2} fontSize="sm">
        Click the button below to manage your subscription via Stripe. You will
        be prompted to enter the email associated with your MugClub.beer
        account.
      </Text>

      <Button
        as={Link}
        leftIcon={<ExternalLinkIcon />}
        href={sessionUrl}
        isExternal
        isLoading={isLoading}
        size="sm"
        variant="outline"
      >
        Open Customer Portal
      </Button>
    </>
  );
}

export default memo(CustomerPortalButton);
